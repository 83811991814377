import {
    BrowserRouter,
    createRoutesFromChildren,
    Routes,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import Cookies from "components/cookie/Cookies";
import { CookieContextProvider } from "context/cookie.context";
import { useEffect } from "react";
import RoutesComponent from "./Routes";
import { useSystemLanguage } from "hooks/system-language.hook";

Sentry.init({
    enabled: import.meta.env.PROD, // for all vite modes run with `vite build`
    environment: import.meta.env.MODE, // staging, production, etc
    dsn: "https://02f98c09a29914395f5c7fcea964001b@o4506319671197696.ingest.sentry.io/4506319679979520",
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    useSystemLanguage();
    return (
        <>
            <BrowserRouter>
                <RoutesComponent CustomRoutes={SentryRoutes} />
            </BrowserRouter>
            <CookieContextProvider>
                <Cookies />
            </CookieContextProvider>
        </>
    );
}

export default Sentry.withProfiler(App);
