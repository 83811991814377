import type { TranslationFunction } from "utils/i18n";

export interface ICookieSettingsItem {
    title: string;
    description: string;
    selected?: boolean;
    disabled?: boolean;
}

// TODO find a way to use enum value with i18n scan script
export enum CookieSettingsItemTitleEnum {
    Required = "Strictly Required Cookies",
    Analytics = "Analytics and Statistics",
    Marketing = "Marketing and Retargeting",
    Functional = "Functional Cookies",
}

export const getCookieSettings = (
    t: TranslationFunction
): ICookieSettingsItem[] => [
    {
        title: t("cookies.strictlyRequired", "Strictly Required Cookies"),
        description: t(
            "cookies.strictlyRequiredDescription",
            "These cookies are required for the website to run and cannot be switched off. Such cookies are only set in response to actions made by you such as language, currency, login session, privacy preferences. You can set your browser to block these cookies but this might affect the way our site is working."
        ),
        selected: true,
        disabled: true,
    },
    {
        title: t("cookies.analytics", "Analytics and Statistics"),
        description: t(
            "cookies.analyticsDescription",
            "These cookies allow us to measure visitors traffic and see traffic sources by collecting information in data sets. They also help us understand which products and actions are more popular than others."
        ),
    },
    {
        title: t("cookies.marketing", "Marketing and Retargeting"),
        description: t(
            "cookies.marketingDescription",
            "These cookies are usually set by our marketing and advertising partners. They may be used by them to build a profile of your interest and later show you relevant ads. If you do not allow these cookies you will not experience targeted ads for your interests."
        ),
    },
    {
        title: t("cookies.functional", "Functional Cookies"),
        description: t(
            "cookies.functionalDescription",
            "These cookies enable our website to offer additional functions and personal settings. They can be set by us or by third-party service providers that we have placed on our pages. If you do not allow these cookies, these or some of these services may not work properly."
        ),
    },
];
