import { Route, useLocation } from "react-router-dom";
import { ROUTER_PATHS } from "constants/router.constants";
import ProtectedRoute from "components/ProtectedRoute";
import React, { lazy, useEffect, FC } from "react";
import LazyLoader, { withLazyLoader } from "components/features/LazyLoader";

// Define your lazy-loaded components
const CallbackComponent = lazy(() => import("components/AuthCallbackComponent"));
const AppLayout = lazy(() => import("components/AppLayout"));
const ProjectPageWrapper = lazy(() => import("components/ProjectPageWrapper"));
const ProjectList = lazy(() => import("components/project/ProjectList/ProjectList"));
const ProjectPage = lazy(() => import("components/project/ProjectPage"));
const ChatBot = lazy(() => import("components/chatbot/default/ChatBot"));
const LinkUpGame = lazy(() => import("components/LinkUpGame"));
const ResponsiveSwiperDemo = lazy(() => import("components/slider/ResponsiveSwiperDemo"));
const CreateOptionsOverview = lazy(() => import("components/CreateOptionsOverview"));
const SupersetDashboard = lazy(() => import("components/project/SupersetDashboard"));
const SlideSetEditor = lazy(() => import("components/SlideSetEditor"));
const Chats = lazy(() => import("components/Chats"));
const Sales = lazy(() => import("components/Sales"));
const ChatBotAdmin = lazy(() => import("components/chatbot/ChatBotAdmin"));
const DashboardWelcomeScreen = lazy(() => import("components/dashboard/DashboardWelcomeScreen"));
const Homepage = lazy(() => import("components/Homepage"));
const CustomDomainAddModal = lazy(() => import("components/project/CustomDomainAddModal"));
const CustomDomainVerifyModal = lazy(() => import("components/project/CustomDomainVerifyModal"));
const CustomDomainDeleteModal = lazy(() => import("components/project/CustomDomainDeleteModal"));
const CustomDomainManage = lazy(() => import("components/project/CustomDomainManage"));

// Define a type for the preloading function
type PreloadComponentFunction = () => Promise<any>;

// Preload function
const preloadComponent = (componentImportFunction: PreloadComponentFunction) => {
    componentImportFunction().catch(error => console.error(`Preloading failed: ${error}`));
};

// Hook to preload components when the component mounts
const usePreloadComponents = (components: PreloadComponentFunction[], shouldPreload: boolean) => {
    useEffect(() => {
        if (shouldPreload) {
            components.forEach(preloadComponent);
        }
    }, [components, shouldPreload]);
};

const RoutesComponent: FC<{
    CustomRoutes: React.ComponentType<{ children?: React.ReactNode }>
}> = ({ CustomRoutes }) => {
    const location = useLocation();
    const shouldPreload =
        location.pathname.split("?")[0] !== ROUTER_PATHS.swipe;

    // Call the hook to preload components on mount, except when on the swipe route
    usePreloadComponents(
        [
            () => import("components/AuthCallbackComponent"),
            () => import("components/AppLayout"),
            () => import("components/ProjectPageWrapper"),
            () => import("components/project/ProjectList/ProjectList"),
            () => import("components/project/ProjectPage"),
            () => import("components/chatbot/default/ChatBot"),
            () => import("components/LinkUpGame"),
            () => import("components/slider/ResponsiveSwiperDemo"),
            () => import("components/CreateOptionsOverview"),
            () => import("components/project/SupersetDashboard"),
            () => import("components/SlideSetEditor"),
            () => import("components/Chats"),
            () => import("components/Sales"),
            () => import("components/chatbot/ChatBotAdmin"),
            () => import("components/dashboard/DashboardWelcomeScreen"),
            () => import("components/Homepage"),
            () => import("components/project/CustomDomainAddModal"),
            () => import("components/project/CustomDomainVerifyModal"),
            () => import("components/project/CustomDomainDeleteModal"),
            () => import("components/project/CustomDomainManage"),
        ],
        shouldPreload,
    );

    return (
        <CustomRoutes>
            <Route
                path={ROUTER_PATHS.loginCallback}
                element={withLazyLoader(<CallbackComponent />)}
            />
            <Route
                element={
                    <ProtectedRoute>
                        <LazyLoader>
                            <AppLayout />
                        </LazyLoader>
                    </ProtectedRoute>
                }
            >
                <Route
                    path={ROUTER_PATHS.projects}
                    element={withLazyLoader(<ProjectPageWrapper />)}
                >
                    <Route index element={withLazyLoader(<ProjectList />)} />

                    <Route path={ROUTER_PATHS.domainsPage} element={withLazyLoader(<CustomDomainManage />)}>
                        <Route
                            path={ROUTER_PATHS.customDomainAdd}
                            element={withLazyLoader(<CustomDomainAddModal />)}
                        />
                        <Route
                            path={ROUTER_PATHS.customDomainVerify}
                            element={withLazyLoader(
                                <CustomDomainVerifyModal />,
                            )}
                        />
                        <Route
                            path={ROUTER_PATHS.customDomainDelete}
                            element={withLazyLoader(
                                <CustomDomainDeleteModal />,
                            )}
                        />
                    </Route>

                    <Route
                        path={ROUTER_PATHS.projectPage}
                        element={withLazyLoader(<ProjectPage />)}
                    />
                </Route>
                
                <Route
                    path={ROUTER_PATHS.create}
                    element={withLazyLoader(<CreateOptionsOverview />)}
                />
                <Route
                    path={ROUTER_PATHS.projectAnalyticsPage}
                    element={withLazyLoader(<SupersetDashboard />)}
                />
                <Route
                    path={ROUTER_PATHS.swipeEditor}
                    element={withLazyLoader(<SlideSetEditor />)}
                />
                <Route
                    path={ROUTER_PATHS.chats}
                    element={withLazyLoader(<Chats />)}
                />
                <Route
                    path={ROUTER_PATHS.sales}
                    element={withLazyLoader(<Sales />)}
                />
                <Route
                    path={ROUTER_PATHS.chatAdmin}
                    element={withLazyLoader(<ChatBotAdmin />)}
                />
                <Route
                    path={ROUTER_PATHS.demoAnalytics}
                    element={withLazyLoader(<DashboardWelcomeScreen />)}
                />
            </Route>
            <Route index element={withLazyLoader(<Homepage />)} />
            <Route
                path={ROUTER_PATHS.game}
                element={withLazyLoader(<LinkUpGame />)}
            />
            <Route
                path={ROUTER_PATHS.swipe}
                element={withLazyLoader(<ResponsiveSwiperDemo />)}
            />
            <Route
                path={ROUTER_PATHS.chat}
                element={withLazyLoader(<ChatBot />)}
            />
        </CustomRoutes>
    );
};

export default RoutesComponent;