import { useState } from "react";
import { useTranslationHook } from "utils/i18n";

type LanguageInfo = {
    abbreviation: string;
    name: string;
};

type Languages = {
    [key: string]: LanguageInfo;
};

const languages: Languages = {
    "en-US": { abbreviation: "EN", name: "English" },
    "de-DE": { abbreviation: "DE", name: "Deutsch" },
    "it-IT": { abbreviation: "IT", name: "Italiano" },
    "fr-FR": { abbreviation: "FR", name: "Français" },
    "es-ES": { abbreviation: "ES", name: "Español" },
    "pt-BR": { abbreviation: "PT", name: "Português" },
    // Add more languages as needed
};

export const localesSupported = Object.keys(languages);

function LanguageSwitcher() {
    const { i18n, language } = useTranslationHook();
    const [currentLanguage, setCurrentLanguage] = useState(language);    

    const changeLanguage = (lng: string) => {
        if (lng === currentLanguage) {
            return;
        }
        i18n.changeLanguage(lng);
        setCurrentLanguage(lng);
    };

    return (
        <div className="relative border border-gray-400 rounded">
            {/* Icon placeholder (adjust as needed) */}
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span>🌐</span> {/* Replace with appropriate icon */}
            </div>

            <select
                onChange={(e) => changeLanguage(e.target.value)}
                value={currentLanguage}
                className="block pl-10 pr-8 py-1 w-full bg-white rounded leading-tight focus:outline-none focus:shadow-outline appearance-none border-0 bg-chevron-down bg-no-repeat bg-right bg-auto cursor-pointer text-gray-700 text-sm font-medium font-inter lineHeight-custom"
                data-cy="language-switcher"
            >
                {Object.keys(languages).map((langCode) => (
                    <option key={langCode} value={langCode}>
                        {`${languages[langCode].name} (${languages[langCode].abbreviation})`}
                    </option>
                ))}
            </select>

            {/*TODO improve from UI design (figma) then replace to icons*/}
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M7.69607 10.0444L7.69613 10.0444C7.7756 10.12 7.88857 10.1666 8.01138 10.1667L7.69607 10.0444ZM7.69607 10.0444L3.96274 6.49609L3.96279 6.49603M7.69607 10.0444L3.96279 6.49603M3.96279 6.49603L3.9564 6.49017M3.96279 6.49603L3.9564 6.49017M3.9564 6.49017C3.91602 6.4531 3.88532 6.41017 3.86474 6.36464C3.8442 6.31918 3.83379 6.2713 3.83336 6.22358C3.83292 6.17586 3.84245 6.1279 3.8621 6.08224C3.88179 6.0365 3.91161 5.99321 3.95117 5.95561C3.99078 5.91796 4.03928 5.8869 4.09446 5.86544C4.14968 5.84397 4.20965 5.83289 4.27066 5.83339C4.33166 5.83389 4.39136 5.84596 4.44606 5.8683C4.50073 5.89062 4.54852 5.9224 4.58731 5.96057L4.58726 5.96062M3.9564 6.49017L4.58726 5.96062M4.58726 5.96062L4.59355 5.96661M4.58726 5.96062L4.59355 5.96661M4.59355 5.96661L7.66702 8.88774L8.01148 9.21512M4.59355 5.96661L8.01148 9.21512M8.01148 9.21512L8.35594 8.88774M8.01148 9.21512L8.35594 8.88774M8.35594 8.88774L11.4256 5.97018C11.5057 5.8979 11.6171 5.8542 11.7373 5.85519C11.8588 5.8562 11.9702 5.90269 12.0489 5.97741C12.1267 6.05136 12.1658 6.14592 12.1667 6.23911C12.1675 6.33126 12.1309 6.42512 12.0565 6.49967L8.32689 10.0444L8.32683 10.0444M8.35594 8.88774L8.32683 10.0444M8.32683 10.0444C8.24736 10.12 8.13439 10.1666 8.01158 10.1667L8.32683 10.0444Z"
                        fill="#6B7280"
                        stroke="#6B7280"
                    />
                </svg>
            </div>
        </div>
    );
}

export default LanguageSwitcher;
