import { FC, ReactNode, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/Loading";

type Props = {
    children: ReactNode;
};
const ProtectedRoute: FC<Props> = ({ children }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    useEffect(() => {
        (async () => {
            if (!isLoading && !isAuthenticated) {
                await loginWithRedirect();
            }
        })();
    }, [isAuthenticated, isLoading, loginWithRedirect]);

    if (isLoading) return <Loading />;

    if (!isLoading && isAuthenticated) return <>{children}</>;

    return <></>;
};

export default ProtectedRoute;
