import { FC } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import CookieSettingsItem from "./CookieSettingsItem";
import { useCookie } from "../../context/cookie.context";
import { getCookieSettings, ICookieSettingsItem } from "./cookie-settings.data";
import { useTranslationHook, Trans } from "utils/i18n";

const CookieSettings: FC = () => {
    const { t } = useTranslationHook();
    const COOKIE_SETTINGS = getCookieSettings(t);

    const {
        setIsTermsOfUseOpened,
        setIsSettingsOpened,
        checkedSettings,
        acceptCookies,
    } = useCookie();

    const handleOpenTermsOfUse = () => {
        setIsSettingsOpened(false);
        setIsTermsOfUseOpened(true);
    };

    const handleAcceptAllCookies = () => {
        acceptCookies(
            COOKIE_SETTINGS.map((item: ICookieSettingsItem) => item.title)
        );
    };

    const handleAcceptSelection = () => {
        acceptCookies(checkedSettings);
    };

    return (
        <CookieConsent
            location="none"
            visible={
                getCookieConsentValue("cookie-consent") ? "hidden" : "visible"
            }
            cookieName="cookie-consent"
            disableButtonStyles={true}
            disableStyles={true}
            containerClasses="max-w-full max-h-full h-full w-full lg:w-200 lg:max-w-200 lg:max-h-4/5 lg:h-4/5 p-6 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                flex flex-col pt-6 px-4 pb-9 lg:p-12 bg-white rounded-lg shadow-banner text-base justify-center items-center z-50"
            contentClasses="relative flex flex-col max-h-full lg:max-h-4/5"
            buttonClasses="hidden"
            expires={150}
        >
            <div className="relative h-full lg:h-152 overflow-auto mb-6">
                <h1 className="text-lg font-medium mb-10">
                    {t("cookies.cookieSettingsTitle", "Cookie settings")}
                </h1>
                <div className="relative flex flex-col gap-6">
                    {" "}
                    {COOKIE_SETTINGS.map((item: ICookieSettingsItem, index) => (
                        <CookieSettingsItem key={index} {...item} />
                    ))}
                    <span>
                        <Trans i18nKey="cookies.cookieSettingsAccept">
                            With accepting you agree to the{" "}
                            <button
                                className="inline underline font-medium"
                                onClick={handleOpenTermsOfUse}
                            >
                                Terms of Use
                            </button>
                        </Trans>
                    </span>
                </div>
                <div className="sticky bottom-0 left-0 right-0 w-full h-44 bg-gradient-to-b from-transparent to-white pointer-events-none" />
            </div>
            <div className="w-full flex flex-col lg:flex-row flex-1 gap-x-6 gap-y-4 justify-end">
                <button
                    className="border border-primary-600 text-primary-600 px-4 py-3 rounded-lg disabled:opacity-40"
                    onClick={handleAcceptSelection}
                    disabled={checkedSettings.length === COOKIE_SETTINGS.length}
                >
                    {t("cookies.acceptSelectionBtn", "Accept Selection")}
                </button>
                <button
                    className="bg-primary-600 text-white px-4 py-3 rounded-lg lg:w-52 font-semibold"
                    onClick={handleAcceptAllCookies}
                >
                    {t("cookies.acceptAllBtn", "Accept All")}
                </button>
            </div>
        </CookieConsent>
    );
};

export default CookieSettings;
