import {ChangeEventHandler, FC, useEffect, useMemo} from "react";
import {useCookie} from "../../context/cookie.context";

interface CookieSettingsItemProps {
    title: string;
    description: string;
    selected?: boolean;
    disabled?: boolean
}

const CookieSettingsItem: FC<CookieSettingsItemProps> = ({title, description, selected = false, disabled = false}) => {
    const {checkedSettings, setCheckedSettings} = useCookie();

    const fieldId = title.trim().toLowerCase().replaceAll(' ', '-');
    const isItemSelected = useMemo(() => checkedSettings.includes(title), [title, checkedSettings]);

    useEffect(() => {
        selected && setCheckedSettings(settings => settings.includes(title) ? settings : [...settings, title]);
    }, [])

    const handleSelectItem: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        setCheckedSettings((checkedSettings) => {
                return !isItemSelected
                    ? [...checkedSettings, target.value]
                    : checkedSettings.filter((settingsItem) => settingsItem !== target.value)
            }
        )
    }

    return <div className='flex gap-2'>
        <input type='checkbox'
               id={fieldId}
               name={fieldId}
               className='h-4 w-4 mt-1'
               value={title}
               disabled={disabled}
               checked={isItemSelected}
               onChange={handleSelectItem}
        />
        <div className='flex flex-col'>
            <label htmlFor={fieldId} className='font-medium text-grey-900'>{title}</label>
            <p className='grey-700'>{description}</p>
        </div>
    </div>
}

export default CookieSettingsItem;