export const CUSTOM_DOMAIN_ADD = "customDomainAdd";
export const CUSTOM_DOMAIN_MANAGE = "customDomainManage";
export const CUSTOM_DOMAIN_VERIFY = "customDomainVerify";
export const CUSTOM_DOMAIN_DELETE = "customDomainDelete";
export const DOMAINS = "domains";

export const ROUTER_PATHS = {
    loginCallback: "/login/callback",
    home: "/",
    create: "/data-qr-codes",
    swipe: "/swipe",
    swipeEditor: "/swipe/editor",
    projects: "projects",
    projectPage: "/projects/:projectId",
    projectAnalyticsPage: "/projects/:projectId/analytics",
    domainsPage: `/projects/:projectId/${DOMAINS}`,
    customDomainAdd: `/projects/:projectId/${DOMAINS}/${CUSTOM_DOMAIN_ADD}`,
    customDomainVerify: `/projects/:projectId/${DOMAINS}/${CUSTOM_DOMAIN_VERIFY}/:domainId`,
    customDomainDelete: `/projects/:projectId/${DOMAINS}/${CUSTOM_DOMAIN_DELETE}/:domainId`,
    chat: "/chat",
    chats: "/chats",
    sales: "/sales",
    chatAdmin: "/chat/admin",
    game: "/game",
    demoAnalytics: "/demo/analytics",
}
