import { FC, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import CookieSettings from "./CookieSettings";
import { useCookie } from "../../context/cookie.context";
import CookieTermsOfUse from "./CookieTermsOfUse";
import { getCookieSettings } from "./cookie-settings.data";
import { setConsent } from "firebase/analytics";
import { useTranslationHook, Trans } from "utils/i18n";

const Cookies: FC = () => {
    const {
        cookieId,
        isSettingsOpened,
        setIsSettingsOpened,
        isTermsOfUseOpened,
        cookieConsentValue,
    } = useCookie();
    const { t } = useTranslationHook();
    const COOKIE_SETTINGS = getCookieSettings(t);

    useEffect(() => {
        if (cookieConsentValue === undefined) {
            setConsent({
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "denied",
            });
        }
    }, []);

    const handleOpenSettings = () => {
        setIsSettingsOpened(true);
    };

    const handleAccept = () => {
        setConsent({
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            security_storage: "granted",
        });
    };

    return isSettingsOpened ? (
        <CookieSettings />
    ) : isTermsOfUseOpened ? (
        <CookieTermsOfUse />
    ) : (
        <CookieConsent
            location="bottom"
            buttonText={t("cookies.warning.accept", "Accept")}
            cookieName="cookie-consent"
            disableButtonStyles={true}
            cookieValue={`cookieid:${cookieId};settings:${COOKIE_SETTINGS.map(
                ({ title }) => title
            ).join("|")}`}
            disableStyles={true}
            containerClasses="w-full fixed left-0 bottom-0 flex-col lg:flex-row z-50 flex gap-x-11 gap-y-5 p-6 lg:pt-[19px] lg:pb-[11px] lg:px-[38px]
                            bg-white rounded-lg shadow-banner text-base justify-center items-center"
            buttonWrapperClasses="flex flex-1 w-full"
            buttonClasses="bg-primary-600 text-white px-4 py-3 rounded-lg w-full lg:w-52 font-semibold"
            expires={150}
            onAccept={handleAccept}
        >
            <Trans i18nKey="cookies.warning.message">
                We use cookies to personalise content and ads, provide social
                media features, and analyse traffic to and on our website.
                Customise{" "}
                <button
                    className="inline underline font-medium"
                    onClick={handleOpenSettings}
                >
                    cookies settings here
                </button>
                .
            </Trans>
        </CookieConsent>
    );
};

export default Cookies;
