import { FC } from "react";
import { Trans } from "utils/i18n";

const CookieTermsOfUseContent: FC = () => {
    return (
        <div className="flex flex-col">
            <Trans i18nKey="cookies.termsOfUseContent">
                <p className="mb-2">
                    Thank you for visiting our website. The protection of your
                    personal data is important to us. In this Policy, we would
                    like to inform you about how we handle your data in
                    accordance with Art. 13 of the General Data Protection
                    Regulation (GDPR).
                </p>
                <p className="mb-2">
                    Here you will find our privacy policy for the social media
                    pages we use: Privacy policy for our social media pages -{" "}
                    <em>see below</em>
                </p>

                <h3 className="text-lg font-bold mt-4 mb-2">
                    Information about the responsible party (referred to as the
                    “controller” in the GDPR)
                </h3>

                <p className="mb-2">
                    The data processing controller on this website is:
                </p>
                <p className="mb-2">QR-Marketing GmbH</p>
                <p className="mb-2">Überseeallee 10</p>
                <p className="mb-2">20457 Hamburg (Germany)</p>
                <p className="mb-2">Telefon: +49 (0)4104 – 693 250</p>
                <p className="mb-2">E-Mail: info@qrmarketing.com</p>
                <p className="mb-2">
                    The controller is the natural person or legal entity that
                    single-handedly or jointly with others makes decisions as to
                    the purposes of and resources for the processing of personal
                    data (e.g. names, e-mail addresses, etc.).
                </p>

                <h3 className="text-lg font-bold mt-4 mb-2">
                    Designation of a data protection officer as mandated by law
                </h3>

                <p className="mb-2">
                    We have appointed a data protection officer for our company:
                </p>
                <p className="mb-2">datenschutz nord GmbH</p>
                <p className="mb-2">
                    Sechslingspforte 2 22087 Hamburg (Deutschland)
                </p>
                <p className="mb-2">Telefon: +49 (0)40 – 593 616 0400</p>
                <p className="mb-2">E-Mail:dataprotection@qrmarketing.com</p>

                <h2 className="text-2xl font-bold mt-4 mb-2">Usage Data</h2>

                <p className="mb-2">
                    When you visit our website, our web server temporarily
                    evaluates usage data for statistical purposes in order to
                    improve the quality of our website. This data consists of
                    the following data categories:
                </p>

                <ul className="list-disc list-inside">
                    <li>the name and address of the requested content,</li>
                    <li>the date and time of the query,</li>
                    <li>of the transferred data volume,</li>
                    <li>
                        the access status (content transferred, content not
                        found),
                    </li>
                    <li>
                        the description of the used web browser and operating
                        system,
                    </li>
                    <li>
                        the referral link, which indicates from which page you
                        reached ours,
                    </li>
                    <li>
                        the IP address of the requesting computer, which is
                        shortened in such a way that a personal reference can no
                        longer be established.
                    </li>
                </ul>

                <p className="mb-2">
                    The above-mentioned log data will only be evaluated
                    anonymously.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Necessary Cookies
                </h2>

                <p className="mb-2">
                    On our website, we use cookies which are necessary in order
                    for the site to function. Cookies are small text files that
                    can be placed on your computer or mobile device by websites
                    that you visit. A distinction is made between session
                    cookies, which are deleted as soon as you close your
                    browser, and permanent cookies, which are stored beyond the
                    individual session. We do not use these necessary cookies
                    for analysis, tracking or advertising purposes. In some
                    cases, these cookies only contain information on certain
                    settings and cannot be linked to a person. They may also be
                    necessary to enable user guidance, security and
                    implementation of the site. The legal basis for using these
                    cookies is our legitimate interest according to Art. 6 (1)
                    (f) GDPR. You can set your browser to inform you about the
                    placement of cookies. This is in order to make the use of
                    cookies transparent for you. You can also delete cookies or
                    prevent the setting of new cookies at any time by using the
                    appropriate browser settings. Please note that if you delete
                    certain cookies, our web pages may not be displayed
                    correctly and some functions may no longer be available.
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Maximum Storage Period</th>
                            <th>Adequate level of data protection</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">Mixpanel</td>
                            <td className="text-center">
                                Understanding usage behaviour
                            </td>
                            <td className="text-center">60 months</td>
                            <td className="text-center">
                                Processing within EU/EEA
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2 className="text-2xl font-bold mt-4 mb-2">Contact Form</h2>

                <p className="mb-2">
                    You may contact us via our contact form. In order to use our
                    contact form, we will require you to provide the data marked
                    as mandatory.
                </p>
                <p className="mb-2">
                    The legal basis for this processing is Art. 6 (1) (f) GDPR,
                    being our legitimate interest to respond to your request.
                    Your data will only be used to process your request. We
                    delete your data if they are no longer required and there
                    are no legal obligations to retain them. Where the
                    processing of your data is based on legitimate interest in
                    accordance with Art. 6 (1) (f) GDPR, you have the right to
                    object to that processing at any time. To do so, please use
                    the email address provided in the imprint.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Google Tag Manager
                </h2>

                <p className="mb-2">
                    We use the service called Google Tag Manager from Google.
                    "Google" is a group of companies and consists of the
                    companies Google Ireland Ltd. (provider of the service),
                    Gordon House, Barrow Street, Dublin 4, Ireland and Google
                    LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043, USA
                    and other affiliated companies of Google LLC. We have
                    concluded an AVV/DPA with Google.
                </p>
                <p className="mb-2">
                    The Google Tag Manager is a solution with which we can
                    assign a tag to each of our pages and manage them. With the
                    help of the tags, the Google Tag Manager can only use
                    analysis and tracking tools for us where they make sense.
                    The Google Tag Manager is an auxiliary service and only
                    processes personal data for technically necessary purposes.
                    The Google Tag Manager ensures that other components are
                    loaded, which in turn may collect data. The Google Tag
                    Manager does not access this data. For more information
                    about Google Tag Manager, see Google's privacy policy.
                </p>
                <p className="mb-2">
                    Please note that American authorities, such as secret
                    services, could possibly gain access to personal data due to
                    American laws such as the Cloud Act, which is inevitably
                    exchanged with Google due to the Internet Protocol (TCP)
                    when this service is integrated.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Google Analytics
                </h2>

                <p className="mb-2">
                    This website uses the "Google Analytics" service provided by
                    Google Ireland Ltd. (provider of the service), Gordon House,
                    Barrow Street, Dublin 4, Irland, to analyze website usage by
                    users.
                </p>
                <p className="mb-2">
                    The service uses cookies. The information generated by the
                    cookies will be transmitted to and stored by Google on a
                    server in the USA. On this website IP anonymization takes
                    effect. The IP address of the user is shortened within the
                    member states of the EU and the European Economic Area. This
                    shortening eliminates the personal reference to your IP
                    address.
                </p>
                <p className="mb-2">
                    As part of the agreement on the order data agreement, which
                    the website operators have concluded with Google Ireland
                    Ltd., uses the information collected to create an evaluation
                    of website usage and website activity and provides services
                    associated with internet use.
                </p>

                <h3 className="text-lg font-bold mt-4 mb-2">
                    Withdrawal of your consent
                </h3>

                <p className="mb-2">
                    You have the option to prevent the cookies from being stored
                    on your device by making appropriatesettings in your
                    browser. There is no guarantee that you will be able to
                    access all functions and features of this website without
                    restrictions if your browser does not allow cookies.
                </p>
                <p className="mb-2">
                    You can also use a browser plug-in to prevent the
                    information collected by cookies (including your IP address)
                    from being transmitted to Google Inc. and used by Google
                    Inc. The following link takes you to the corresponding
                    plugin:{" "}
                    <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                        https://tools.google.com/dlpage/gaoptout?hl=en
                    </a>
                </p>
                <p className="mb-2">
                    By clicking on the link above, you download an "opt-out
                    cookie" with which you prevent Google Analytics from
                    collecting data about you within this website.
                </p>
                <p className="mb-2">
                    Your browser should therefore allow the local storage of
                    cookies. If you remove your cookies regularly, you will need
                    to click the link again each time.
                </p>
                <p className="mb-2">
                    Here you will find further information on the use of data by
                    Google:{" "}
                    <a href="https://support.google.com/analytics/answer/6004245?hl=en">
                        https://support.google.com/analytics/answer/6004245?hl=en
                    </a>
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">Google Ads</h2>

                <p className="mb-2">
                    To evaluate and optimize Google Ads marketing measures, we
                    use a mechanism with the help of which our campaign
                    successes can be assigned to the respective Google Ads
                    advertisements after they have been clicked on. This allows
                    us to measure the effectiveness of Google Ads
                    advertisements.
                </p>
                <p className="mb-2">
                    We process your personal data for tracking on our website
                    based on the following legal basis:
                </p>
                <p className="mb-2">
                    Your consent according to Art. 6 Para. 1 Uabs. 1 letter a)
                    GDPR
                </p>

                <h3 className="text-lg font-bold mt-4 mb-2">
                    Withdrawal of your consent
                </h3>

                <p className="mb-2">
                    We only use Google Analytics with your consent. You can
                    revoke consent once you have given it:
                </p>

                <ul className="list-disc list-inside">
                    <li>
                        prevent the installation of cookies by setting your
                        browser software accordingly; however, we would like to
                        point out that in this case you may not be able to use
                        all the functions of our website to their full extent;
                    </li>
                    <li>
                        download and install the browser plug-in available under
                        the following link:{" "}
                        <a href="http://tools.google.com/dlpage/gaoptout?hl=en">
                            http://tools.google.com/dlpage/gaoptout?hl=en
                        </a>
                        ;
                    </li>
                    <li>object to the use of Google Analytics.</li>
                </ul>

                <p className="mb-2">
                    You can find more detailed information on the terms of use
                    and data protection for Google at{" "}
                    <a href="https://policies.google.com/terms?hl=en">
                        https://policies.google.com/terms?hl=en
                    </a>{" "}
                    or at{" "}
                    <a href="https://policies.google.com/?hl=en&gl=en">
                        https://policies.google.com/?hl=en&gl=en
                    </a>
                    .
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">Storage Period</h2>

                <p className="mb-2">
                    Unless otherwise specified, we will delete your personal
                    data if they are no longer required for the relevant
                    processing purposes and no legal retention obligations
                    oppose deletion.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Data Processors
                </h2>

                <p className="mb-2">
                    We transfer your data to service providers who support us in
                    the operation of our websites and related processes. These
                    service providers are usually data processors within the
                    meaning of Art. 28 GDPR. Our service providers are strictly
                    bound by contracts and our instructions.
                </p>
                <p className="mb-2">
                    Any processors who may not have been previously disclosed
                    are listed below. If data is transferred outside the EU or
                    the EEA, we will also provide information on the adequate
                    level of data protection.
                </p>

                <table>
                    <thead>
                        <tr>
                            <th>Service Provider/ Processor</th>
                            <th>Purpose</th>
                            <th>Adequate level of data protection</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                Amazon Web Services (AWS)
                            </td>
                            <td className="text-center">Webhosting</td>
                            <td className="text-center">
                                Processing within EU/EEA​​ (Germany)
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Heroku</td>
                            <td className="text-center">Webhosting</td>
                            <td className="text-center">
                                Processing within EU/EEA​​ (Germany)
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Calendly</td>
                            <td className="text-center">Appointment booking</td>
                            <td className="text-center">
                                Processing within EU/EEA
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Your rights as a data subject
                </h2>

                <p className="mb-2">
                    When processing your personal data, the GDPR grants you
                    certain rights as a data subject:
                </p>

                <ul className="list-disc list-inside">
                    <li>
                        <strong>
                            Right of access by the data subject (Art. 15 GDPR)
                        </strong>
                        : You have the right to obtain confirmation as to
                        whether personal data concerning you are being
                        processed; if this is the case, you have the right to be
                        informed of this personal data and to receive the
                        information specified in Art. 15 GDPR.
                    </li>
                    <li>
                        <strong>Right to rectification (Art. 16 GDPR)</strong>:
                        You have the right to rectification of inaccurate
                        personal data concerning you and, taking into account
                        the purposes of the processing, the right to have
                        incomplete personal data completed, including by means
                        of providing a supplementary statement without delay.
                    </li>
                    <li>
                        <strong>Right to erasure (Art. 17 GDPR)</strong>: You
                        have the right to obtain the erasure of personal data
                        concerning you without undue delay if one of the reasons
                        listed in Art. 17 GDPR applies.
                    </li>
                    <li>
                        <strong>
                            Right to restriction of processing (Art. 18 GDPR)
                        </strong>
                        : You have the right to request the restriction of
                        processing if one of the conditions listed in Art. 18
                        GDPR is met, e.g. if you have objected to the
                        processing, for the duration of our examination.
                    </li>
                    <li>
                        <strong>
                            Right to data portability (Art. 20 GDPR)
                        </strong>
                        : In certain cases, which are listed in detail in Art.
                        20 GDPR, you have the right to receive the personal data
                        concerning you in a structured, commonly used and
                        machine-readable format, or to request that this data be
                        transferred to a third party.
                    </li>
                    <li>
                        <strong>Right to withdraw consent (Art. 7 GDPR)</strong>
                        : If the processing of data is based on your consent,
                        you are entitled to withdraw your consent to the use of
                        your personal data at any time in accordance with Art. 7
                        (3) GDPR. Please note that the withdrawal is only
                        effective for the future. Processing that took place
                        before the withdrawal is not affected.
                    </li>
                    <li>
                        <strong>Right to object (Art. 21 GDPR)</strong>: If data
                        is collected on the basis of Art. 6 (1) (f) GDPR (data
                        processing for the purpose of our legitimate interests)
                        or on the basis of Art. 6 (1) (e) GDPR (data processing
                        for the purpose of protecting public interests or in the
                        exercise of official authority), you have the right to
                        object to the processing at any time for reasons arising
                        from your particular situation. We will then no longer
                        process the personal data unless there are compelling
                        legitimate grounds for the processing which override
                        your interests, rights and freedoms or if data is still
                        needed for the establishment, exercise or defence of
                        legal claims.
                    </li>
                    <li>
                        <strong>
                            Right to lodge a complaint with a supervisory
                            authority (Art. 77 GDPR)
                        </strong>
                        : According to Art. 77 GDPR, you have the right to lodge
                        a complaint with a supervisory authority if you believe
                        that the processing of your data violates data
                        protection regulations. This right may be asserted in
                        particular with a supervisory authority in the Member
                        State of your habitual residence, your place of work, or
                        the place of the suspected infringement.
                    </li>
                </ul>

                <p className="mb-2">
                    <strong>Asserting your rights</strong>: Unless otherwise
                    described above, please contact us to assert your rights.
                    You will find our contact details in our imprint.
                </p>

                <h2>Privacy policy for our social media pages</h2>

                <p className="mb-2">
                    When you visit our social media pages, it may be necessary
                    to process data relating to you. We would therefore like to
                    inform you in accordance with Art. 13 of the General Data
                    Protection Regulation (GDPR) about the handling of your data
                    and your rights resulting from this.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Data Controller
                </h2>

                <p className="mb-2">
                    We, QR-Marketing GmbH, currently administer the following
                    social media pages:
                </p>

                <ul className="list-disc list-inside">
                    <li>
                        LinkedIn:{" "}
                        <a href="https://www.linkedin.com/company/qr-marketing/">
                            https://www.linkedin.com/company/qr-marketing/
                        </a>
                    </li>
                </ul>

                <p className="mb-2">
                    Our contact details can be found in our legal notice:{" "}
                    <a href="https://qrmarketing.com/imprint">
                        https://qrmarketing.com/imprint
                    </a>
                    .
                </p>
                <p className="mb-2">
                    Besides us, the respective provider of the social media
                    platform is also responsible for processing your personal
                    data. As far as we can influence this and parameterize the
                    data processing, we will work within the scope of the
                    possibilities available to us, in order to ensure that the
                    provider of the social media platform handles your data in a
                    manner that complies with data protection regulations. In
                    this context, please also read the data protection policy of
                    the respective social media platform.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Data processing by us
                </h2>

                <p className="mb-2">
                    The data you enter on our social media pages, such as
                    usernames, comments, videos, pictures, likes, public
                    messages, etc., are published by the social media platform
                    and are no time processed by us for other purposes. We only
                    reserve the right to delete content if necessary. We may
                    share your content on our website if this is a feature of
                    the social media platform and communicate with you through
                    the social media platform if necessary.
                </p>
                <p className="mb-2">
                    If you send us a request on the social media platform,
                    depending on the content, we may also refer you to other,
                    secure communication channels that guarantee
                    confidentiality. For example, you have the possibility to
                    send us your inquiries at any time to the address{" "}
                    <a href="https://qrmarketing.com/about">
                        https://qrmarketing.com/about
                    </a>{" "}
                    or{" "}
                    <a href="https://qrmarketing.com/de/getstarted">
                        https://qrmarketing.com/de/getstarted
                    </a>{" "}
                    given in the legal notice. The choice of the appropriate
                    communication channel is your own responsibility.
                </p>
                <p className="mb-2">
                    The legal basis for the processing of your data is Art. 6
                    para. 1 lit. f GDPR. The data processing is based on the
                    legitimate interest of carrying out public relations and
                    communication.
                </p>
                <p className="mb-2">
                    Some social media platforms generate statistics based on
                    usage data, which contain information about your interaction
                    with our social media site. We cannot influence or prevent
                    the execution and provision of these statistics. However, we
                    do not make use of optional statistics from the social media
                    platform. If you wish to object to a certain data processing
                    on which we have an influence, please use the contact data
                    given in the legal notice.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Storage Duration
                </h2>

                <p className="mb-2">
                    We delete your personal data if they are no longer required
                    for the aforementioned processing purposes and no legal
                    storage obligations prevent deletion.
                </p>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Data processing by the operator of the social media platform
                </h2>
                <p className="mb-2">
                    The provider of the social media platform uses web tracking
                    methods. Web tracking can also be performed regardless of
                    whether you are logged in or registered with the social
                    media platform.
                </p>
                <p className="mb-2">
                    We would therefore like to inform you that it cannot be
                    excluded that the provider of the social media platform may
                    use your profile and behavioural data to evaluate your
                    habits, personal relationships, preferences, etc. In this
                    respect, we have no influence on the processing of your data
                    by the provider of the social media platform, so that the
                    use of the social media platform is at your own
                    responsibility.
                </p>
                <p className="mb-2">
                    Further information on data processing by the provider of
                    the social media platform, configuration options for the
                    protection of your privacy as well as further objection
                    options and, if available and concluded, the agreement
                    pursuant to Art. 26 GDPR, can be found in the provider's
                    privacy policy:
                </p>
                <ul className="list-disc list-inside">
                    <li>
                        LinkedIn:{" "}
                        <a href="https://www.linkedin.com/legal/privacy-policy">
                            https://www.linkedin.com/legal/privacy-policy
                        </a>{" "}
                        and{" "}
                        <a href="https://legal.linkedin.com/pages-joint-controller-addendum">
                            https://legal.linkedin.com/pages-joint-controller-addendum
                        </a>
                    </li>
                </ul>
                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Your rights as users
                </h2>
                <p className="mb-2">
                    As a website user, you have the opportunity to assert the
                    following rights against us as well as against the provider
                    of the social media platform:
                </p>
                <ul className="list-disc list-inside">
                    <li>
                        <strong>Right of access (Article 15 GDPR)</strong>
                    </li>
                    <p className="mb-2">
                        You have the right to obtain confirmation as to whether
                        personal data concerning you is being processed; if this
                        is the case you have the right to be informed about this
                        personal data and to the information specified in
                        Article 15 GDPR.
                    </p>
                    <li>
                        <strong>
                            Right to rectification and erasure (Articles 16 and
                            17 GDPR)
                        </strong>
                    </li>
                    <p className="mb-2">
                        You have the right to obtain without undue delay the
                        rectification of inaccurate personal data concerning you
                        and, if necessary, the right to have incomplete personal
                        data completed.
                    </p>
                    <p className="mb-2">
                        You also have the right to request that personal data
                        relating to you shall be deleted without undue delay if
                        one of the reasons listed in Art. 17 GDPR applies, e.g.
                        if the data is no longer needed for the purposes for
                        which it was collected.
                    </p>
                    <li>
                        <strong>
                            Right to restriction of processing (Article 18 GDPR)
                        </strong>
                    </li>
                    <p className="mb-2">
                        If one of the conditions set forth in Art. 18 GDPR
                        applies, you shall have the right to restrict the
                        processing of your data to mere storage, e.g. if you
                        revoke consent, to the processing, for the duration of a
                        possible examination.
                    </p>
                    <li>
                        <strong>
                            Right to data portability (Article 20 GDPR)
                        </strong>
                    </li>
                    <p className="mb-2">
                        In certain cases, which are listed in detail in Art. 20
                        GDPR, you have the right to receive the personal data
                        concerning you in a structured, well-established and
                        machine-readable format, respectively to request the
                        transfer of this data to a third party.
                    </p>
                    <li>
                        <strong>Right to object (Article 21 GDPR)</strong>
                    </li>
                    <p className="mb-2">
                        If the data is collected on the basis of Art. 6 para. 1
                        lit. f GDPR (data processing for the purposes of the
                        legitimate interests), you have the right to object to
                        the processing at any time for reasons arising out of
                        your particular situation. We will then no longer
                        process personal data, unless there are demonstrably
                        compelling reasons for processing, that are worthy of
                        protection and override your interests, rights and
                        freedoms, or the processing serves to assert, exercise
                        or defend legal claims.
                    </p>
                    <p className="mb-2">
                        If the data are processed on the basis of the legitimate
                        interest for the purpose of direct marketing, you have
                        your own right of objection, which you may exercise at
                        any time without giving reasons and the exercise of
                        which will lead to the termination of the processing for
                        the purpose of direct marketing.
                    </p>
                    <li>
                        <strong>
                            Right to lodge a complaint with a supervisory
                            authority
                        </strong>
                    </li>
                    <p className="mb-2">
                        According to Art. 77 GDPR, you have the right to lodge a
                        complaint with a supervisory authority if you believe
                        that the processing of the data concerning you infringes
                        data protection regulations. In particular, the right to
                        lodge a complaint may be invoked before a supervisory
                        authority in the member state where you are resident,
                        your place of work or the place of the suspected
                        infringement.
                    </p>
                </ul>

                <h2 className="text-2xl font-bold mt-4 mb-2">
                    Contact details for the data protection representative
                </h2>

                <p className="mb-2">
                    Our external data protection officer is available to provide
                    you with information on data protection.
                </p>
                <p className="mb-2">Please contact:</p>
                <p className="mb-2">datenschutz nord GmbH</p>
                <p className="mb-2">
                    Sechslingspforte 2, 22087 Hamburg (Deutschland)
                </p>
                <p className="mb-2">Phone: +49 (0)40 – 593 616 0400</p>
                <p className="mb-2">
                    Web: <a href="http://www.dsn-group.de">www.dsn-group.de</a>
                </p>
                <p className="mb-2">E-Mail: office@datenschutz-nord.de</p>
                <p className="mb-2">Please contact first:</p>
                <p className="mb-2">E-Mail: dataprotection@qrmarketing.com</p>
                <p className="mb-2">
                    If you contact our data protection officer, please also
                    indicate the responsible office mentioned in the legal
                    notice.
                </p>
            </Trans>
        </div>
    );
};

export default CookieTermsOfUseContent;
