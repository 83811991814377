export const config = {
    environment: import.meta.env.VITE_ENVIRONMENT,
    isProduction: (
        // @ts-ignore: VITE_ENVIRONMENT is set in the .env file
        import.meta.env.VITE_ENVIRONMENT === "production"
        || (import.meta.env.VITE_ENVIRONMENT !== "development" && import.meta.env.VITE_ENVIRONMENT !== 'local')
    ),
    auth: {
        // Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
        domain: "auth.scanvoyage.ai",
        clientId: "00LigqlY7FoPVbh6mrB4AJ9x6BXIzAxU",
        audience: "https://qrmarketing.eu.auth0.com/api/v2/",
    },
}
