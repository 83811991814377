import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider, AppState } from "@auth0/auth0-react";
import { config } from "./config";
import history from "./utils/history";
import "./utils/i18n";
import Modal from "react-modal";
import "./index.css";

const onRedirectCallback = (appState: AppState) => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

// if local storage contains parameter loggedOut, then we want to allow users to log in using different accounts
const allowLoginSelection = localStorage.getItem("loggedOut") === "true";

const providerConfig = {
    domain: config.auth.domain,
    clientId: config.auth.clientId,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri:
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port !== "443" && ":" + window.location.port) +
            "/login/callback",
        // redirect_uri: 'https://scanvoyage.ai/login/callback',
        audience: config.auth.audience,
        prompt: allowLoginSelection ? "login" : "select_account",
        scope: "profile email",
    },
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

Modal.setAppElement("#root");

const wrappedApp = (
    // @ts-ignore
    <Auth0Provider {...providerConfig}>
        <App />
    </Auth0Provider>
);

root.render(<React.StrictMode>{wrappedApp}</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
