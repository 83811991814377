import { FC } from "react";
import CookieConsent from "react-cookie-consent";
import { useCookie } from "../../context/cookie.context";
import CookieTermsOfUseContent from "./CookieTermsOfUseContent";
import { getCookieSettings } from "./cookie-settings.data";
import { useTranslationHook } from "utils/i18n";

const CookieTermsOfUse: FC = () => {
    const { setIsTermsOfUseOpened, setIsSettingsOpened, acceptCookies } =
        useCookie();

    const handleOpenSettings = () => {
        setIsTermsOfUseOpened(false);
        setIsSettingsOpened(true);
    };

    const { t } = useTranslationHook();
    const COOKIE_SETTINGS = getCookieSettings(t);

    const handleAcceptAllCookies = () => {
        acceptCookies(COOKIE_SETTINGS.map((item) => item.title));
    };

    return (
        <CookieConsent
            location="none"
            cookieName="cookie-consent"
            disableButtonStyles={true}
            disableStyles={true}
            containerClasses="max-w-full max-h-full h-full w-full lg:w-200 lg:max-w-200 lg:max-h-full lg:h-4/5 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col pt-6 px-4 pb-9 lg:p-12
                    bg-white rounded-lg shadow-banner text-base justify-center items-center z-50"
            contentClasses="relative flex flex-col w-full max-h-full lg:max-h-4/5"
            buttonClasses="hidden"
            expires={150}
        >
            <div className="relative w-full h-full lg:h-152 overflow-y-auto overflow-x-hidden mb-6">
                <h1 className="text-lg font-medium mb-10">
                    {t("cookies.consent.tou", "Terms of Use")}
                </h1>
                <CookieTermsOfUseContent />
                <div className="sticky bottom-0 left-0 right-0 w-full h-44 bg-gradient-to-b from-transparent to-white pointer-events-none" />
            </div>
            <div className="w-full flex flex-col lg:flex-row flex-1 gap-x-6 gap-y-4 justify-end">
                <button
                    className="border border-primary-600 text-primary-600 px-4 py-3 rounded-lg"
                    onClick={handleOpenSettings}
                >
                    {t("cookies.consent.settings", "Settings")}
                </button>
                <button
                    className="bg-primary-600 text-white px-4 py-3 rounded-lg lg:w-52 font-semibold"
                    onClick={handleAcceptAllCookies}
                >
                    {t("cookies.consent.acceptAll", "Accept All")}
                </button>
            </div>
        </CookieConsent>
    );
};

export default CookieTermsOfUse;
