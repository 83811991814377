import i18n, { t } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { useTranslation, initReactI18next, Trans } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { localesSupported } from "./LanguageSwitcher";

i18n.use(LanguageDetector)
    .use(
        resourcesToBackend(
            (language: string, namespace: string) => {
                if (!localesSupported.includes(language)) return null;

                return import(`./locales/${language}/${namespace}.json`)
            }
        )
    )
    .use(initReactI18next)
    .init({
        debug: false,
        ns: ["common"], // default ns name without explicitly set option is 'translation'
        defaultNS: "common",
        saveMissing: false, // do we support this feature ??? // you should not use saveMissing in production
        saveMissingTo: "all",
        fallbackLng: "en-US", // fallback language is used when the current language is not available; required for automatically updating translation keys

        interpolation: {
            escapeValue: false,
        },

        detection: {
            convertDetectedLanguage,
        },
    });

export function convertDetectedLanguage(language: string) {
    // If only language detected without region (e.g., "de"), convert to "de-DE"
    if (language && language.length === 2) {
        return `${language}-${language.toUpperCase()}`;
    }

    return language;
}

export default i18n;

export const useTranslationHook = () => {
    const { t, i18n } = useTranslation();
    return { t, i18n, language: i18n.language };
};

export type TranslationFunction = typeof t;

export { Trans };
