import clsx from "clsx";
import { useTranslation } from "react-i18next";

// loading spinner in the middle of the screen
type LoadingProps = {
    type?: 'spinner' | 'text';
    fullScreen?: boolean;
}
const Loading = ({ type = 'spinner', fullScreen = true }: LoadingProps) => {
    const { t } = useTranslation();

    return (
        <div className={clsx("spinner top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2", fullScreen ? 'fixed' : 'absolute')}>
            {type === 'spinner'
                ? <span className="loader" />
                : (
                    <div className="text-2xl font-bold">
                        {t("loading", "Loading...")}
                    </div>
                )
            }
        </div>
    )
};

export default Loading;
