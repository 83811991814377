import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useMemo,
    useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Cookies } from "react-cookie-consent";
import {
    getCookieSettings,
    CookieSettingsItemTitleEnum,
} from "../components/cookie/cookie-settings.data";
import { setConsent } from "firebase/analytics";
import { useTranslationHook } from "utils/i18n";

interface ICookieContext {
    cookieId: string;
    isSettingsOpened: boolean;
    setIsSettingsOpened: Dispatch<SetStateAction<boolean>>;
    isTermsOfUseOpened: boolean;
    setIsTermsOfUseOpened: Dispatch<SetStateAction<boolean>>;
    checkedSettings: string[];
    setCheckedSettings: Dispatch<SetStateAction<string[]>>;
    acceptCookies: (settings: string[]) => void;
    cookieConsentValue?: string;
}

const CookieContext = createContext<ICookieContext | undefined>(undefined);

export const useCookie = () => {
    const context = useContext(CookieContext);
    if (context === undefined) {
        throw new Error(
            "useCookie must be used within a CookieContextProvider"
        );
    }
    return context;
};

export const CookieContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isSettingsOpened, setIsSettingsOpened] = useState(false);
    const [isTermsOfUseOpened, setIsTermsOfUseOpened] = useState(false);
    const { t } = useTranslationHook();
    const COOKIE_SETTINGS = getCookieSettings(t);

    const [checkedSettings, setCheckedSettings] = useState<string[]>(
        COOKIE_SETTINGS.map((item) => item.title)
    );

    const cookieId = useMemo(() => uuidv4(), []);

    const acceptCookies = (settings: string[]) => {
        const settingsList = settings.join("|");
        Cookies.set(
            "cookie-consent",
            `cookieid:${cookieId};settings:${settingsList}`,
            { expires: 150 }
        );
        setConsent({
            ad_storage: settings.includes(CookieSettingsItemTitleEnum.Marketing)
                ? "granted"
                : "denied",
            analytics_storage: settings.includes(
                CookieSettingsItemTitleEnum.Analytics
            )
                ? "granted"
                : "denied",
            functionality_storage: settings.includes(
                CookieSettingsItemTitleEnum.Functional
            )
                ? "granted"
                : "denied",
            security_storage: settings.includes(
                CookieSettingsItemTitleEnum.Required
            )
                ? "granted"
                : "denied",
        });
        setIsSettingsOpened(false);
        setIsTermsOfUseOpened(false);
    };

    return (
        <CookieContext.Provider
            value={{
                cookieId,
                isSettingsOpened,
                setIsSettingsOpened,
                isTermsOfUseOpened,
                setIsTermsOfUseOpened,
                checkedSettings,
                setCheckedSettings,
                acceptCookies,
                cookieConsentValue: Cookies.get("cookie-consent"),
            }}
        >
            {children}
        </CookieContext.Provider>
    );
};
