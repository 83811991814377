import {ErrorInfo, Fragment, ReactElement, ReactNode, Suspense} from "react";
import { ErrorBoundary } from "react-error-boundary";
import Loading from "components/Loading";

const defaultSuspenseFallback = <Loading fullScreen={false} />;
const defaultErrorFallback = <div>Something went wrong</div>;

type LazyLoaderProps = {
    suspenseFallback?: ReactNode;
    errorFallback?: ReactElement;
    children: ReactNode;
}
export default function LazyLoader({ children, ...props }: LazyLoaderProps) {
    const suspenseFallback = props.suspenseFallback || defaultSuspenseFallback;
    const errorFallback = props.errorFallback || defaultErrorFallback;

    const withErrorBoundary = false; // TODO: find a better way of handling component errors

    return withErrorBoundary ? (
        <ErrorBoundary fallback={errorFallback}
                       onError={(error: Error, info: ErrorInfo) => {
                           console.error(error, info);
                       }}>
            <Suspense fallback={suspenseFallback}>
                {children}
            </Suspense>
        </ErrorBoundary>
    ) : (
        <Suspense fallback={suspenseFallback}>
            {children}
        </Suspense>
    )
}

export function withLazyLoader(Component: ReactElement, lazyLoaderProps?: Omit<LazyLoaderProps, 'children'>) {
    return (
        <LazyLoader {...lazyLoaderProps}>
            {Component}
        </LazyLoader>
    )
}
