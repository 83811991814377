import { useEffect } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { convertDetectedLanguage, useTranslationHook } from "utils/i18n";

// TODO this can probably be replaced by proper import of i18n.use(LanguageDetector) included in `utils/i18n`, check why it does not work as intended

const detectSystemLanguage = () => {
    // Check preferred languages in the browser and system language
    const userLanguages = navigator.languages || [
        navigator.language || "en-US",
    ];
    return convertDetectedLanguage(userLanguages[0]);
};

export const useSystemLanguage = () => {
    const [systemLanguage, saveSystemLanguage] = useLocalStorage<string | null>(
        "systemLanguage",
        null
    );

    const { i18n } = useTranslationHook();

    useEffect(() => {
        const currentSystemLanguage = detectSystemLanguage();

        // Change language when browser/system language is changed
        if (
            systemLanguage !== currentSystemLanguage &&
            i18n.language !== currentSystemLanguage
        ) {
            void i18n.changeLanguage(currentSystemLanguage);
            saveSystemLanguage(currentSystemLanguage);
        }
    }, []);
};
